import React from "react";
import { Link } from "react-router-dom";

import { Button, Container, Grid, Stack } from "@mui/material";

import Book from "../components/Book";
import portadaPC from "../imgs/PortadaPC.jpg";
import portadaCSM from "../imgs/PortadaComposicionShowsMentalismo.jpg";

const MagiaScreen = () => {
    return (
        <Container
            className={"text-focus-in"}
            sx={{
                minHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
            }}
        >
            <br />
            <Grid
                container
                direction={{ sm: "column", md: "row" }}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid item sm={12} md={6}>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Book
                            image={portadaPC}
                            url="https://amzn.eu/d/dq3BYOi"
                            name="Pocket Card"
                            author={"Por Alejandro Loda"}
                            subline={"El enfile del mentalista"}
                        />
                        <Button
                            variant="outlined"
                            component={Link}
                            to={"/magia/pocketcard"}
                        >
                            Descubre más
                        </Button>
                        <br />
                        <br />
                    </Stack>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Book
                            image={portadaCSM}
                            url="https://amzn.eu/d/dq3BYOi"
                            name="Composición de Shows de Mentalismo"
                            author={"Por Alejandro Loda"}
                            subline={"Próximamente a la venta"}
                        />
                        <Button
                            variant="outlined"
                            component={Link}
                            to={"/magia/composicionsm"}
                        >
                            Descubre más
                        </Button>
                        <br />
                        <br />
                    </Stack>
                </Grid>
            </Grid>
            <br />
            <br />
        </Container>
    );
};

export default MagiaScreen;
