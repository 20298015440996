import React from "react";
import { Link } from "react-router-dom";

import { Button, Container, Typography } from "@mui/material";

const PCQRLibroScreen = () => {
    return (
        <Container
            className={"text-focus-in"}
            sx={{
                height: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <Typography
                variant="h5"
                component="h2"
                sx={{ mt: 2, color: "text.secondary" }}
            >
                Pronto información secreta sobre la Pocket Card
            </Typography>
            <br />
            <br />
            <br />
            <Button
                variant="outlined"
                component={Link}
                to={"/magia/pocketcard"}
            >
                Pocket Card
            </Button>
        </Container>
    );
};

export default PCQRLibroScreen;
