import React from "react";

const VIForzajeYControl = () => {
    return (
        <div>
            Deberás forzar y controlar el 5♠ en la séptima posición contando
            desde bottom.
            <br />
            Existen muchos métodos para conseguir esto, y puedes aplicar el
            sistema con el que más cómodo estes.
            <br />
            <br />
            Este es el que yo uso:
            <br />
            <br />
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>Toma la baraja con la carta a forzar en top (5♠).</li>
                <li>
                    Haz una mezcla por arrastre, pelando la carta de top1 para
                    que tras la mezcla quede en bottom1.
                </li>
                <li>
                    Realiza una pequeña extensión en las manos de las primeras 4
                    a 7 cartas.
                </li>
                <li>
                    Con el dedo corazón de la mano que recibe las cartas de la
                    extensión, tira de la carta de bottom para situarla al mismo
                    nivel que la primera carta, pero bajo la extensión.
                </li>
                <li>
                    Sigue pasando cartas en la extensión, cuadrando de que la
                    carta de bottom no se vea
                </li>
                <li>
                    Pídele a un espectador que <b>toque</b> una carta
                </li>
                <li>
                    Por la carta que tocó, separa la baraja en dos mitades,
                    estando esta carta como la supuesta inferior del paquete de
                    la mano derecha (donde se reciben las cartas), aunque
                    realmente la inferior será la carta a forzar.
                </li>
                <li>
                    Al cuadrar las cartas de la mano derecha, la carta de bottom
                    debería ser la del espectador, aunque es la forzada.
                    Verticaliza esta mano, de manera que el espectador vea la
                    carta que queremos forzar.
                </li>
                <li>
                    Una vez vista, haz el movimiento de unir los paquetes de las
                    manos, pero marca un <i>break</i> en el corte
                </li>
                <li>
                    Realiza un <i>doble corte</i> de manera que la carta del
                    espectador acabe en bottom
                </li>
                <li>
                    Ahora haz una mezcla por arrastre en la que pelarás las
                    primeras 6 cartas y las colocarás en bottom.
                </li>
                <li>
                    Haz otra mezcla por arrastre, pero no coloques las cartas
                    que arrastras en bottom, sino sobre el paquete.
                </li>
            </ul>
            <br />
            Con esto, el 5♠ estará en la séptima posición desde bottom.
            <br />
            <br />
            Por último realiza una <b>extensión</b> en la mesa que sea similar a
            la de la fotografía, de manera que las cartas de bottom tengan más
            separación que las de top.
        </div>
    );
};

export default VIForzajeYControl;
