import React, { useState } from "react";

import {
    Box,
    Typography,
    Stack,
    Menu,
    MenuItem,
    IconButton,
    Container,
} from "@mui/material";

import Icons from "../../helpers/icons";

import Materiales from "./FotoFantasma/Materiales";
import VidaExterna from "./FotoFantasma/VidaExterna";
import Preparacion from "./FotoFantasma/Preparacion";
import VIForzajeYControl from "./FotoFantasma/VIForzajeYControl";
import VICambioDeFoto from "./FotoFantasma/VICambioDeFoto";
import Consejos from "./FotoFantasma/Consejos";
import Video from "./FotoFantasma/Video";

const TITLE = "Foto Fantasma";
// Datos de la vida interna y los pasos
const steps = [
    { title: "Vídeo explicación", content: Video },
    { title: "Vida Externa", content: VidaExterna },
    { title: "Materiales", content: Materiales },
    { title: "Preparación", content: Preparacion },
    { title: "Vida Interna - Forzaje y control", content: VIForzajeYControl },
    { title: "Vida Interna - Cambio de foto", content: VICambioDeFoto },
    { title: "Consejos", content: Consejos },
];

const FotoFantasma = () => {
    // Estado para el paso activo
    const [activeStep, setActiveStep] = useState(0);

    // Menu lista
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Abrir el menú en la posición del botón
    };
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null); // Cerrar el menú
    };

    // Función para manejar el cambio de paso
    const handleStep = (step) => {
        setActiveStep(step); // No renderizar condicionalmente hooks aquí
    };

    // Función para manejar el siguiente paso
    const handleNext = () => {
        setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    };

    // Función para manejar el paso anterior
    const handleBack = () => {
        setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    return (
        <>
            <Container>
                <Box
                    sx={{
                        height: "calc(100vh - 64px - 105px )",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h5">{TITLE}</Typography>
                    {/* Stepper no lineal con botones clicables */}
                    <Box
                        sx={{
                            flexShrink: 0,
                            overflowX: "auto",
                            overflowY: "hidden",
                            maxHeight: "64px",
                            scrollbarWidth: "thin", // Soporte para Firefox
                            scrollbarColor: "rgba(0, 0, 0, 0.5) transparent", // Color en Firefox
                            "&::-webkit-scrollbar": {
                                height: "5px", // Tamaño pequeño de la barra horizontal
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "rgba(0, 0, 0, 0.5)", // Color de la barra
                                borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.8)", // Color más visible al hacer hover
                            },
                            "&::-webkit-scrollbar-button": {
                                display: "none", // Oculta las flechas de scroll en los extremos
                            },
                        }}
                    ></Box>

                    {/* Bloque de contenido con scroll */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowY: "auto",
                            padding: 2,
                            // border: "1px solid lightgray",
                            marginTop: 2,
                        }}
                    >
                        <Typography variant="h4">
                            {steps[activeStep].title}
                        </Typography>
                        <br />
                        {React.createElement(steps[activeStep].content)}
                    </Box>

                    {/* Botones fijos en la parte inferior */}
                </Box>
            </Container>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 2,
                    backgroundColor: "#fff", // Fondo blanco para que resalten los botones
                    boxShadow: "0 -2px 10px rgba(0,0,0,0.1)", // Sombras para que resalten
                }}
            >
                <Stack
                    maxWidth={"100%"}
                    width={"100%"}
                    height={"50px"}
                    direction="row"
                    spacing={0}
                    sx={{
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={handleBack}
                        disabled={activeStep === 0}
                    >
                        <Icons.ArrowLeft />
                    </IconButton>
                    <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Icons.Dot
                            sx={{
                                fontSize: 16,
                                color: "transparent",
                            }}
                        />
                        {steps.map((_, index) => (
                            <Icons.Dot
                                onClick={() => handleStep(index)}
                                key={index}
                                sx={{
                                    fontSize: index === activeStep ? 16 : 12,
                                    color:
                                        index === activeStep
                                            ? "primary.main"
                                            : "grey.400",
                                    transition: "all 0.3s ease-in-out",
                                    "&:hover": {
                                        opacity: 0.5, // slight opacity on hover for better UI interaction
                                    },
                                }}
                            />
                        ))}
                        <Icons.Dot
                            sx={{
                                fontSize: 16,
                                color: "transparent",
                            }}
                        />
                    </Stack>
                    <IconButton
                        onClick={handleNext}
                        disabled={activeStep === steps.length - 1}
                    >
                        <Icons.ArrowRight />
                    </IconButton>
                </Stack>
                <IconButton
                    onClick={handleClick} // Abrir menú
                >
                    <Icons.ListNumbers />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    {steps.map((step, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                handleStep(index);
                                handleClose();
                            }}
                            sx={{
                                backgroundColor:
                                    index === activeStep
                                        ? "primary.main"
                                        : "transparent",
                                color:
                                    index === activeStep
                                        ? "common.white"
                                        : "text.primary",
                                "&:hover": {
                                    backgroundColor: "primary.light",
                                    color: "common.white",
                                },
                            }}
                        >
                            {step.title}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </>
    );
};

export default FotoFantasma;
