import React from "react";

const VIPrimerBillete = () => {
    return (
        <div>
            A continuación se describe como producir el primero de los billetes.
            <br />
            <b>Producción por tap.</b>
            <br />
            <br />
            Partimos de la situación de tener las 3 tarjetas de crédito en la
            mesa, y haber cambiado la carta datáfono, por la Pocket Card que
            tiene el billete de 10€.
            <br />
            <br />
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Con la Pocket Card en la mano izquierda, toma una de las
                    tarjetas. Enseña ambos elementos de manera casual por delate
                    y por detrás. Ten cuidado de tapar correctamente el bolsillo
                    de la Pocket Card.
                </li>
                <li>
                    Coloca la tarjeta descuadrada sobre la PC, mientras
                    preguntas de quién era esta.
                </li>
                <li>
                    Cuando te digan de quién y vayas a tomar la tarjeta, extrae
                    bajo esta el billete.
                </li>
                <li>
                    El movimiento ahora será el de golpear la PC con la tarjeta
                    que tiene el billete bajo esta:
                    <br />
                    <br />
                    Tras separar hacia arriba la tarjeta (subir), unos 7-10cm,
                    golpearás bajando la mano, pero en la acción de bajar la
                    mano, los dedos corazón e índice, apenas agarran el billete.
                    <br />
                    El movimiento se asemeja a dejar caer el billete desde la
                    punta de los dedos, pero a la vez que bajas la carta. De
                    esta manera, el billete saldrá ligeramente propulsado, y
                    quedará atrapado entre la PC y la tarjeta cuando se toquen.
                    <br />
                    <br />
                    El movimiento de bajada, recuerda a la acción de abanicar,
                    porque describe una pequeña curva. Esta curva ayuda a que el
                    billete quede atrapado visiblemente en medio de la PC y la
                    tarjeta. Ten en cuenta que la tarjeta golpea la PC de manera
                    ladeada, permitiendo que en la imagen final, se puedan ver
                    los tres elementos a la vez.
                </li>
                <li>
                    Haz referencia a que son 10€, que no es un mala cantidad,
                    dando a entender de manera subconciente que está en la
                    media, cualquier billete mayor será que le va bastante bien,
                    y cualquiera menor, que no le va tan bien.
                </li>
                <li>Devuelve al espectador su tarjeta.</li>
            </ul>
        </div>
    );
};

export default VIPrimerBillete;
