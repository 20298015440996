import React from "react";

const VIPrimerosPasos = () => {
    return (
        <div>
            El inicio del juego, sería el siguiente:
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>Saca sobre la mesa la carta normal con el datáfono.</li>
                <li>Pide 3 espectadores que participarán desde el público.</li>
                <li>
                    A cada uno de esto, dale una o dos gomets, y diles que
                    saquen su tarjeta de crédito o débito, y las peguen tapando
                    el código de seguridad, así como alguno de los números.
                </li>
                <li>
                    Entrega a un espectador de las primeras filas la carta con
                    el datáfono para que la vea, y explica al público que es
                    efectivamente una carta con una pegatina de un datáfono.
                </li>
                <li>
                    Dile a los 3 espectadores, que te presten unos minutos sus
                    tarjetas, pero que en breve se las devolverás intactas.
                </li>
                <li>
                    Deja las tarjetas en la mesa, y toma la carta datáfono del
                    espectador.
                </li>
                <li>
                    En el proceso de sentarte, cambia la carta datáfono, por la
                    que tienes en la silla, que es la PC Horizontal que tiene
                    los 10€.
                </li>
                <li>
                    Explica que con este datáfono mágico podemos ver "que tal de
                    pasta va" cada uno de los espectadores.
                </li>
            </ul>
        </div>
    );
};

export default VIPrimerosPasos;
