import React, { useState } from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";

import Book from "../../components/Book";
import Icons from "../../helpers/icons";

import QueAporta from "../../data/QueAportaALaMagiaYAlMentalismo.pdf";
import portadaImage from "../../imgs/PortadaPC.jpg";

const PocketCardScreen = () => {
    const [openAccordion, setOpenAccordion] = useState("");

    const updateAccordion = (newAcc) =>
        setOpenAccordion(openAccordion === newAcc ? "" : newAcc);
    return (
        <Container
            className={"text-focus-in"}
            sx={{
                minHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
            }}
        >
            <Container>
                <br />
                <br />
                <Book
                    image={portadaImage}
                    url="https://amzn.eu/d/dq3BYOi"
                    name="Pocket Card"
                    author={"Por Alejandro Loda"}
                    subline={"El enfile del mentalista"}
                />
                <br />
                <Accordion
                    sx={{ width: "100%" }}
                    expanded={openAccordion === "sobreElLibro"}
                    onClick={() => updateAccordion("sobreElLibro")}
                >
                    <AccordionSummary expandIcon={<Icons.ArrowDown />}>
                        <b>Sobre el libro</b>
                    </AccordionSummary>
                    <AccordionDetails>
                        La <strong>Pocket Card</strong>, o carta bolsillo, es un
                        <i> gimmick</i> innovador que permite a los magos y
                        mentalistas realizar una amplia gama de efectos
                        impresionantes, desde <u>cambios de papeletas</u> hasta{" "}
                        <u>apariciones</u>, <u>desapariciones</u> y{" "}
                        <u>forzajes</u>, todo esto de manera{" "}
                        <b>invisible para el público</b>. En este libro de
                        magia, Alejandro Loda comparte su experiencia y
                        conocimientos sobre la creación y manejo de la Pocket
                        Card, ofreciendo instrucciones detalladas y ejemplos
                        prácticos. <br />
                        <br />
                        Este libro es una guía completa que te enseñará a{" "}
                        <b>fabricar</b> tus propias Pocket Cards, aprender sus{" "}
                        <b>manejos y técnicas</b>, y a utilizarlas en{" "}
                        <b>11 efectos mágicos diferentes</b>. Con ilustraciones
                        claras y explicaciones paso a paso, es perfecto tanto
                        para principiantes como para profesionales que desean
                        incorporar esta herramienta versátil en sus actuaciones.{" "}
                        <br />
                        <br />
                        <b>
                            Descubre el potencial de la Pocket Card y eleva tu
                            magia a un nuevo nivel.
                        </b>
                        <br />
                        <br />
                        <i>"El enfile del mentalista".</i>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{ width: "100%" }}
                    expanded={openAccordion === "indice"}
                    onClick={() => updateAccordion("indice")}
                >
                    <AccordionSummary expandIcon={<Icons.ArrowDown />}>
                        <b>Índice</b>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List disablePadding component="div">
                            {[
                                {
                                    title: "¿Qué es la Pocket Card?",
                                    subchapters: [],
                                },
                                {
                                    title: "¿Qué aporta a la magia y al mentalismo?",
                                    subchapters: [],
                                },
                                {
                                    title: "Explicación del gimmick",
                                    subchapters: [],
                                },
                                {
                                    title: "¿Cómo fabricarla?",
                                    subchapters: [],
                                },
                                {
                                    title: "Variantes",
                                    subchapters: [],
                                },
                                {
                                    title: "La papeleta",
                                    subchapters: [],
                                },
                                {
                                    title: "Manejos básicos",
                                    subchapters: [
                                        "Cambio simple bajo la papeleta",
                                        "Cambio lateral opuesto",
                                        "Cambio a una mano",
                                        "Cambio por esquina cortada",
                                        "Cambio por debajo en mesa",
                                        "Cambio al verticalizar",
                                        "Cambio con múltiple papeleta por arriba",
                                        "Cambio con múltiple papeleta por abajo",
                                        "Extracción a una mano",
                                        "Intercambio lateral opuesto",
                                        "Intercambio Sándwich",
                                        "Intercambio por esquina",
                                        "Robo con la papeleta",
                                        "Mostrada señalando",
                                        "Mostrada con papeleta",
                                        "Mostrada múltiple en abanico",
                                        "Mostrada con giro",
                                        "Producción de billete",
                                        "Producción frotando",
                                    ],
                                },
                                {
                                    title: "Efectos",
                                    subchapters: [
                                        "Jumbo Match",
                                        "PC Predicción de billete",
                                        "PC Coincidencia ESP",
                                        "Money Maker",
                                        "Predicción triple de cara o cruz",
                                        "Pizarras Espiritistas PC",
                                        "Cartas que disminuyen (Pocket Canguro)",
                                        "Uno por delante libre",
                                        "Foto fantasma",
                                        "Predicción de periódico",
                                        "La carta exacta",
                                    ],
                                },
                                {
                                    title: "Justificación de la PC en los juegos",
                                    subchapters: [],
                                },
                                {
                                    title: "Origen de la idea",
                                    subchapters: [],
                                },
                                {
                                    title: "Predecesores",
                                    subchapters: [],
                                },
                            ].map((chapter, index) => (
                                <div key={index}>
                                    {/* Capítulo Principal */}
                                    <ListItem sx={{ p: 0 }}>
                                        <ListItemText primary={chapter.title} />
                                    </ListItem>
                                    {chapter.subchapters.length > 0 && (
                                        <List component="div" disablePadding>
                                            {chapter.subchapters.map(
                                                (subchapter, subIndex) => (
                                                    <ListItem
                                                        key={subIndex}
                                                        sx={{
                                                            pl: 4,
                                                            pt: 0,
                                                            pb: 0,
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={subchapter}
                                                        />
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    )}
                                    {/* {index < chapters.length - 1 && <Divider />} */}
                                </div>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    sx={{ width: "100%" }}
                    expanded={openAccordion === "queAporta"}
                    onClick={() => updateAccordion("queAporta")}
                >
                    <AccordionSummary expandIcon={<Icons.ArrowDown />}>
                        <span>
                            Extracto: <br />
                            <b>¿Qué aporta a la magia y al mentalismo?</b>
                        </span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <embed
                            src={QueAporta}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                        />
                    </AccordionDetails>
                </Accordion>
                <br />
            </Container>
            <br />
            <br />
            <br />
        </Container>
    );
};

export default PocketCardScreen;
