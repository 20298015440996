import React from "react";

const Preparacion = () => {
    return (
        <div>
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>Coloca el 5♠ en el top de la baraja.</li>
                <li>
                    Coloca la fotografía de la extensión dentro de la Pocket
                    Card, debe estar orientada de manera que cuando la extraigas
                    esté boca abajo.
                </li>
                <li>
                    Mete dentro del sobre la foto en blanco, la cámara con su
                    foto dentro, y la impresora.
                </li>
            </ul>
        </div>
    );
};

export default Preparacion;
