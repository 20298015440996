import React from "react";

const VICambioDeFoto = () => {
    return (
        <div>
            Una vez hecha la extensión en la mesa con el 5♠ en séptima posición,
            deberás enseñar la cámara y la impresora, así como dar a comprobar
            la fotografía y cambiarla.
            <br />
            <br />
            Si tienes mi libro <i>Pocket Card: El enfile del mentalista</i>,
            consulta <b>Intercambio Sándwich</b>.
            <br />
            <br />
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>Saca los 3 elementos de un sobre.</li>
                <li>
                    Explica que en tus manos tienes una fotografía sin revelar,
                    y unas cartas con una imagen de un cámara, similar a una
                    Polaroid, y una impresora.
                </li>
                <li>
                    Da a examinar la foto de manera casual mientras enseñas la
                    cámara y la impresora con cuidado de no mostrar el bolsillo.
                </li>
                <li>
                    Explica que algunas cámaras solían traer una impresora
                    integrada, en este caso no, pero la impresora funciona por
                    Bluetooth. En el proceso, extrae bajo la impresora la
                    fotografía impresa del bolsillo de la PC de la cámara. Y
                    coloca la impresora con la foto revelada sobre la cámara.
                </li>
                <li>Recupera la foto del público.</li>
                <li>
                    Di que hay que colocar el papel en la impresora y sacar una
                    foto para que se pueda imprimir una foto. En ese momento
                    estas introduciendo la foto en blanco en el bolsillo de la
                    Pocket Card, aunque el público deberá verlo como que la
                    estás metiendo entre la impresora y la cámara. Este
                    movimiento debe ser fluido.
                </li>
                <li>
                    Una vez entre en el bolsillo, separa casualmente la
                    impresora para que vean la foto que hemos cambiado, al estar
                    boca abajo creerán que es la misma fotografía.
                </li>
                <li>
                    Deja la impresora con la foto en la mesa simulando que la
                    foto está en la posición que ocuparía un papel en una
                    impresora.
                </li>
                <li>
                    Con la cámara haz como que vas a sacar una foto, pero antes
                    de hacerlo, pregunta por el valor de la carta que el
                    espectador vio.
                </li>
                <li>
                    "Toma" la foto, y di que va viajando por Bluetooth a la
                    impresora. Tapa con el pulgar el bolsillo al señalar a la
                    impresora (<b>Mostrada señalando</b>).
                </li>
                <li>
                    Deja la cámara a un lado sin darle importancia, pero con el
                    bolsillo apuntando a ti, para que no se pueda ver desde el
                    público.
                </li>
                <li>
                    Ahora empuja lentamente la foto bajo la impresora, mientras
                    haces "ruiditos de impresora".
                </li>
                <li>
                    Cuando atraviese la impresora, dale la vuelta y revela que
                    la fotografía se ha impreso (<b>Primer efecto</b>).
                </li>
                <li>
                    Dile al espectador que se fije que hay una carta dada la
                    vuelta, y que es la suya (<b>Segundo efecto</b>).
                </li>
                <li>
                    Por último, dile que cuente en que posición de izquierda a
                    derecha está en la foto, y en la extensión voltea esa carta
                    (la séptima) (<b>Tercer efecto</b>).
                </li>
            </ul>
        </div>
    );
};

export default VICambioDeFoto;
