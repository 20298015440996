import React from "react";

const Materiales = () => {
    return (
        <div>
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>Pocket Card con cámara (horizontal de dorso)</li>
                <li>Carta normal con impresora</li>
                <li>Fotografía con extensión</li>
                <li>Fotografía en blanco</li>
                <li>Baraja de cartas con un 5♠</li>
            </ul>
        </div>
    );
};

export default Materiales;
