import React from "react";

import { Box, Grid, Typography } from "@mui/material";

import "../styles/book.css";

const Book = ({ image, url, name, author, subline }) => {
    return (
        <Grid
            container
            direction={{ sm: "column", md: "row" }}
            spacing={2}
            sx={{
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grid item xs={12} sm={12} md={6}>
                <figure class="book align">
                    {/* <!-- Front --> */}

                    <ul class="hardcover_front">
                        <li>
                            <div
                                class="coverDesign portada"
                                style={{
                                    backgroundImage: `url(${image})`,
                                }}
                            ></div>
                        </li>
                        <li></li>
                    </ul>

                    {/* <!-- Pages --> */}

                    <ul class="page">
                        <li></li>
                        <li>
                            <a class="btn" href={url} target="_blank">
                                Cómpralo
                            </a>
                        </li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>

                    {/* <!-- Back --> */}

                    <ul class="hardcover_back">
                        <li></li>
                        <li></li>
                    </ul>
                    <ul class="book_spine">
                        <li></li>
                        <li></li>
                    </ul>
                </figure>
            </Grid>
            <Grid item sm={12} md={6}>
                <Box
                    sx={{
                        textAlign: {
                            xs: "center", // Para pantallas extra pequeñas (xs) y sm
                            sm: "center", // Para pantallas pequeñas (sm)
                            md: "left", // Para pantallas medianas (md) en adelante
                        },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: {
                            xs: "center", // Centrar en pantallas pequeñas
                            sm: "center",
                            md: "flex-start", // Alinear a la izquierda en pantallas medianas o más grandes
                        },
                    }}
                >
                    <Typography
                        variant="h4"
                        fontWeight={"bold"}
                        fontSize={"1.74rem"}
                    >
                        {name}
                    </Typography>
                    <Typography>{author}</Typography>
                    <Typography sx={{ pt: 2 }} color="grey">
                        {subline}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Book;
