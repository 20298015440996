import React from "react";

const Consejos = () => {
    return (
        <div>
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Recuerda detenerte en el primer efecto: la impresión de la
                    fotografía. Esto es también un efecto, así que deja que el
                    público disfrute del momento mágico. Puede ser incluso más
                    potente el hecho de que la fotografía esté impresa, que el
                    que sea la carta.
                </li>
                <li>
                    Antes de hacer el juego, revisa que la fotografía que está
                    dentro de la Pocket Card no se haya pegado. La humedad y
                    tenerla mucho tiempo sin moverse puede ocasionar que sea
                    difícil de extraer del bolsillo. Para arreglar esto,
                    simplemente extráela unos minutos antes de hacer el efecto.
                </li>
                <li>
                    Si el pegamento de los bordes de la Pocket Card ceden,
                    consigue pegamento de contacto transparente Patex o similar.{" "}
                    <br />
                    Coloca una gota de pegamento en la esquina de otra carta, y
                    sin forzar la apertura de la Pocket Card usa esta esquina
                    como pincel.
                    <br /> Ten cuidado de no pegar de más la zona o la
                    fotografía no podrá entrar.
                </li>
                <li>
                    Si las fotografías entran muy justas en el bolsillo, puedes
                    usar una regla y un cutter <b>muy afilado</b>, para cortar
                    medio milímetro de estas y que entren más fácilmente.
                </li>
            </ul>
        </div>
    );
};

export default Consejos;
