import React from "react";

const Preparacion = () => {
    return (
        <div>
            Sentado en una silla en la mesa, deberás tener cada elemento en su
            lugar:
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Sobre la mesa la carta normal con el datáfono, así como
                    pegatinas tipo gomet.
                </li>
                <li>
                    Entre tus piernas: La Pocket Card Horizontal con el billete
                    de 10€ dentro.
                </li>
                <li>
                    Entre tus piernas, al lado de la PC: la araña con el hilo
                    enrollado a su alrededor. El extremo del hilo apuntando a la
                    derecha si eres diestro.
                </li>
                <li>
                    En tu bolsillo del pantalon con tu cartera, la PC vertical
                    con el billete de 20€ dentro.
                </li>
                <li>
                    En tu bolsillo de la chaqueta, la PC Horizontal con el
                    billete de 5€ dentro.
                    <br />
                    Dobla este billete en W, de manera que se pueda quedar de
                    pie cuando aparezca.
                </li>
            </ul>
        </div>
    );
};

export default Preparacion;
