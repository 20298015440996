import React from "react";

const VISegundoBillete = () => {
    return (
        <div>
            A continuación se describe como producir el segundo de los billetes.
            <br />
            <b>Producción por insert.</b>
            <br />
            <br />
            Partimos de la situación de haber producido el primero de los
            billetes. Tendremos ahora una Pocket Card vacía en la mano.
            Deberemos cambiarla por la del billete, y producir la nueva.
            <br />
            <br />
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Después de entregar la tarjeta del primer espectador y
                    volverte a sentar si lo necesitaste, di que tú también vas a
                    participar, y lleva tu mano derecha al bolsillo del
                    pantalón, y extrae tu cartera. <br />
                    En esta acción, tienes en tu mano izquierda el datáfono, que
                    para ayudar a sacar la cartera del bolsillo, también baja
                    bajo la mesa, y puedes cambiar el datáfono por la nueva
                    Pocket card que tienes bajo la cartera.
                </li>
                <li>
                    Saca tu tarjeta de la cartera y deja casualmente la
                    carta-datáfono en la mesa, como si siempre hubiera estado
                    ahí.
                </li>
                <li>
                    Guarda de nuevo tu cartera, y aprovecha la acción para
                    recoger la Pocket Card de antes que está en tu regazo y
                    guardarla en el bolsillo.
                </li>
                <li>
                    Toma otra de las tarjetas y el datáfono, y di que le toca al
                    espectador dueño de esta. Esto hazlo mientras enseñas de
                    manera casual ambos elementos por delante y detrás.
                </li>
                <li>
                    Primero intenta hacer el "tap", pero parece que no funciona.
                    Explica que en estos casos, se suele introducir la tarjeta
                    para poder sacar dinero si el contactless no funciona.
                </li>
                <li>
                    En este momento, estás ejemplificando, dejando la tarjeta{" "}
                    <b>sobre</b> la Pocket Card, alineada, pero hacía ti. Carga
                    el billete bajo la tarjeta.
                </li>
                <li>
                    Ahora sí pondrás la tarjeta bajo la PC simulando que la has
                    insertado en el datáfono.
                </li>
                <li>
                    Con la mano del datáfono (izquierda), agarra el extremo del
                    billete.
                </li>
                <li>
                    Tira de la tarjeta como si la sacaras del datáfono, y el
                    billete aparecerá.
                </li>
                <li>
                    Son 20€, explica que a esta persona se ve que le va muy muy
                    bien.
                </li>
                <li>Devuelve al espectador su tarjeta.</li>
                <li>
                    Quédate un segundo mirándo el billete de la mesa, y di que
                    mejor te lo vas a guardar no vaya a salir volando.
                </li>
                <li>
                    En este momento, aprovecha el guardar el billete en el
                    bolsillo de la chaqueta, para cambiar el datáfono por el que
                    tienes ahí.
                </li>
            </ul>
        </div>
    );
};

export default VISegundoBillete;
