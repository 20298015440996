import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
    AppBar,
    Button,
    Container,
    IconButton,
    Stack,
    Toolbar,
} from "@mui/material";

import Icons from "../helpers/icons";

const Navbar = () => {
    const location = useLocation();
    const getButtonStyle = (path) => {
        return location.pathname === path
            ? { color: "#fff", borderColor: "#fff" } // Borde blanco si la ruta coincide
            : { color: "#fff", borderColor: "transparent" }; // Sin borde si no coincide
    };
    return (
        <Container style={{ padding: "10px" }}>
            <AppBar position="static" sx={{ borderRadius: "12px" }}>
                <Toolbar>
                    {/* <IconButton
                        sx={{ color: "#fff" }}
                        component={Link}
                        to={"/"}
                    >
                        <Icons.Home />
                    </IconButton> */}
                    <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            startIcon={<Icons.Home />}
                            sx={getButtonStyle("/")}
                            component={Link}
                            variant="outlined"
                            to={"/"}
                        >
                            Inicio
                        </Button>
                        <Button
                            variant="outlined"
                            sx={getButtonStyle("/magia")}
                            component={Link}
                            to={"/magia"}
                        >
                            Magia
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>
        </Container>
    );
};

export default Navbar;
