import React, { useEffect, useState } from "react";

import { useSnackbar } from "notistack"; // Importamos useSnackbar
import {
    Container,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import CryptoJS from "crypto-js";

import Icons from "../helpers/icons";
import FotoFantasma from "../components/Juegos/FotoFantasma";
import TelaDePasta from "../components/Juegos/TelaDePasta";

const Juegos = {
    fotoFanstasma: {
        title: "Foto Fantasma",
        hash: "c5cdefaed8cb0977bcd3b54c5590cb29",
        Component: FotoFantasma,
    },
    telaDePasta: {
        title: "Tela de Pasta",
        hash: "f3f9e3216473517c8d018247bb8f67cf",
        Component: TelaDePasta,
    },
};

const ExplicacionScreen = ({ juego }) => {
    const { enqueueSnackbar } = useSnackbar(); // Destructuramos el snackbar

    const [passCorrect, setPassCorrect] = useState(false); //TODO: update to false
    const [password, setPassword] = useState("");

    useEffect(() => {
        // Obtén la contraseña de localStorage al cargar el componente
        const savedPass = localStorage.getItem("lastPass");
        if (savedPass) {
            setPassCorrect(checkPass(savedPass)); // Comprueba si la contraseña es correcta
        }
    }, []);

    const checkPass = (text) => {
        const hashedInput = CryptoJS.MD5(text).toString();

        return hashedInput === Juegos[juego].hash;
    };

    const buttonPass = (text) => {
        if (checkPass(text)) {
            localStorage.setItem("lastPass", text);
            setPassCorrect(true);
        } else {
            enqueueSnackbar("Contraseña incorrecta", { variant: "error" }); // Mostramos snackbar si es incorrecta
        }
    };

    if (Juegos[juego]) {
        return (
            <>
                {!passCorrect ? (
                    <Container>
                        <br />
                        <Typography variant="h5">
                            {Juegos[juego].title ?? ""}
                        </Typography>
                        <br />
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <TextField
                                label="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        buttonPass(password);
                                    }
                                }}
                            />
                            <IconButton
                                onClick={() => buttonPass(password)}
                                sx={{
                                    border: "1px solid #0000003A",
                                    borderRadius: "100px",
                                }}
                            >
                                <Icons.Send />
                            </IconButton>
                        </Stack>
                    </Container>
                ) : (
                    <>{React.createElement(Juegos[juego].Component)}</>
                )}
            </>
        );
    } else return "WrongURL";
};

export default ExplicacionScreen;
