import React from "react";

const VidaExterna = () => {
    return (
        <div>
            El mago pide la ayuda de 3 espectadores que participarán desde el
            público.
            <br />
            Les da una pegatina y les pide que la peguen cubriendo el código de
            su tarjeta de crédito, la cual deberán sacar y sostener en alto.
            <br />
            El mago pasa a su lado y toma las 3 tarjetas de crédito de los
            espectadores, las cuales deja en la mesa.
            <br />
            Paralelamente el mago ha dado a revisar una tarjeta que tiene un
            dibujo de un datáfono a un espectador
            <br />
            <br />
            El mago explica que tiene un datáfono imaginario con el que puede
            ver "que tal va de pasta" la gente.
            <br />
            <br />
            El mago toma la tarjeta del primer espectador, y al acercarla al
            lector del datáfono, aparece un billete de 10€.
            <br /> Al espectador no le va mal.
            <br />
            El mago le devuelve al primer espectador su tarjeta y saca también
            su tarjeta de su cartera, y la deja con la de los otros dos
            espectadores.
            <br />
            <br />
            A continuación prueba la del segundo espectador, pero el "tap" del
            datáfono parece no funcionar, así que hace el gesto de insertarla en
            el datáfono, y aparece un billete de 20€.
            <br />
            A este espectador se ve que le va bastante bien.
            <br />
            Devuelve la tarjeta, pero dice que se va a guardar el billete de 20
            no vaya a salir volando o lo que sea.
            <br />
            <br />
            En tercer lugar, prueba la del espectador restante, el cual parece
            que no funciona ni el "tap", ni meter la tarjeta, así que el mago
            prueba la banda magnética, haciendo aparecer un billete de 5€.{" "}
            <br />
            A este espectador no le va tan bien como a los otros. El mago
            sugiere que quizás esta noche el segundo espectador debería ser el
            que invite.
            <br />
            <br />
            Por último toca probar la del mago, el cual afirma que él "va muy
            bien de pasta".
            <br />
            Ni el "tap", ni insertar la tarjeta parecen funciona, pero al
            deslizarla, en lugar de aparecer un billete, aparece una araña de la
            tarjeta.
            <br />
            El mago dice "bueno, quizás... no me vaya tan bien... yo voy a
            guardar esto y ya luego arreglamos", se guarda los billetes de 10 y
            5€ de la mesa, junto con su tarjeta y el datáfono.
        </div>
    );
};

export default VidaExterna;
