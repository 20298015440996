import { exampleTypes } from "../types/exampleTypes";

const exampleInitial = {
    status: "empty",
    data: {},
};

export const exampleReducer = (state = exampleInitial, action) => {
    switch (action.type) {
        case exampleTypes.loadOne:
            return {
                ...state,
                status: "loaded",
                data: { ...state.data, [action.key]: action.payload },
            };
        case exampleTypes.removeOne:
            let aux = { ...state.data };
            delete aux[action.key];
            return {
                ...state,
                status: "loaded",
                data: aux,
            };
        case exampleTypes.clean:
            return exampleInitial;

        default:
            return state;
    }
};
