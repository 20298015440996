import React from "react";

const Consejos = () => {
    return (
        <div>
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Puedes usar un billete de 50€ en lugar del de 20€, pero no
                    recomiendo usar el de 20 como el de 10. Esto es debido a que
                    el espacio normal de una PC es prácticamente el mismo que el
                    del billete de 20€, eso quiere decir que estará bastante
                    justo, aunque si haces la PC con bastante cuidado y llevando
                    el pegamento muy cerca del límite, es posible usar este
                    billete .
                </li>
                <li>
                    Si no tienes la araña, puedes en la fase final dejar caer un
                    par de monedas de céntimos en su lugar. Aunque encuentro más
                    divertida la versión de la araña.
                </li>
                <li>
                    Cuando introduzcas los billetes en las diferentes PCs, ten
                    cuidad de que se vea desde arriba el número del billete,
                    puesto que al producirlas, se verá resaltado el valor.
                </li>
                <li>
                    El fragmento de la cuerda no enrollada de la araña debe
                    llegarte hasta las yemas de los dedos, y en su extremos
                    coloca un nudito para que sea más fácil de agarrar.
                </li>
            </ul>
        </div>
    );
};

export default Consejos;
