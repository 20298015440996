import React from "react";

const Materiales = () => {
    return (
        <div>
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Pocket Card Horizontal de dorso con la pegatina del datáfono
                    x2.
                </li>
                <li>
                    Pocket Card Vertical de dorso con la pegatina del datáfono.
                </li>
                <li>Carta normal con la pegatina del datáfono.</li>
                <li>Billetes de 5, 10 y 20€.</li>
                <li>Araña de plástico de ~3cm con un hilo atado.</li>
                <li>
                    Pegatinas tipo gomet para tapar los códigos de las tarjetas.
                </li>
            </ul>
        </div>
    );
};

export default Materiales;
