import React from "react";

const VidaExterna = () => {
    return (
        <div>
            Una carta escogida se pierde en medio de la baraja.
            <br />
            Tras mezclar, el mago realiza una extensión boca abajo en la mesa.
            <br /> <br />
            El mago saca dos tarjetas con dibujos de una cámara y de una
            impresora. También saca una foto tipo Polaroid en blanco.
            <br />
            <br />
            El mago coge la “cámara”, y “toma” una foto a la extensión.
            <br />
            Coloca la foto en blanco bajo la impresora. <br />
            Unos segundos después la foto parece revelarse, y aparece una imagen
            de la extensión de la mesa, con la carta elegida daba la vuelta en
            la séptima posición.
            <br />
            <br />
            Se cuentan las cartas de la mesa, y en la posición se encuentra la
            carta del espectador.
        </div>
    );
};

export default VidaExterna;
