import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
const URL = "AkgJFXBT35g";
const title = "Performance y explicación";
const Video = () => {
    return (
        <>
            <Card sx={{ maxWidth: 1000, margin: "auto", mt: 4 }}>
                <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                        {title}
                    </Typography>
                    <div
                        style={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                        }}
                    >
                        <iframe
                            src={`https://www.youtube.com/embed/${URL}`}
                            title={title}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    </div>
                </CardContent>
            </Card>
            <br />
        </>
    );
};

export default Video;
