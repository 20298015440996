import React from "react";

const VITercerBillete = () => {
    return (
        <div>
            A continuación se describe como producir el tercero de los billetes.
            <br />
            <b>Producción por deslizamiento.</b>
            <br />
            <br />
            Partimos de la situación de haber producido el segundo de los
            billetes, y cambiado la PC Vertical, por la horizontal del bolsillo
            de la chaqueta.
            <br />
            <br />
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Toma en la mano derecha la última de las tarjetas, y vuelve
                    a enseñarla de manera casual mientras haces referencia a que
                    esta es la del espectador restante.
                </li>
                <li>Prueba a hacer el "tap", pero no funiona.</li>
                <li>
                    Di que no pasa nada, y prueba a insertarla, pero tampoco
                    funciona.
                </li>
                <li>
                    Deja descansando la tarjeta sobre la Pocket Card mientras
                    gesticulas, y explicas que no pasa nada, porque los
                    datáfonos tienen un tercer sistema, aunque este cada vez se
                    usa menos, que es el lector magnético de la tarjeta.
                </li>
                <li>
                    Aprovecha este momento para cargar el billete bajo la
                    tarjeta al separar las manos.
                </li>
                <li>
                    Ladea tu cuerpo hacia la derecha, de manera que el datáfono
                    quede en horizontal con el público.
                </li>
                <li>
                    Haz una primera pasada rápida, pero sobre la marcha di "ah
                    no así", y gira el datáfono. Con esto conseguimos que la
                    zona de la pegatina que tiene el lector esté más cerca del
                    público, y por consiguiente, el billete cuando aparezca,
                    aparezca en medio de la PC.
                    <br />
                    Si el billete está doblado en W, es posible hacer que
                    aparezca de pie, causando una mayor impresión.
                </li>
                <li>
                    Ahora sí, vuelve a pasar la tarjeta, haciendo un águlo de 90
                    grados entre ambas, y deja caer el billete cuando esté mas o
                    menos centrado.
                    <br />
                    El billete estará apareciendo en medio de la carta.
                </li>
                <li>
                    Al ver el billete de 5€, di que bueno, le va algo más justo
                    que a los otros, especialmente que al de los 20€.
                </li>
                <li>Devuelve la tarjeta al espectador.</li>
            </ul>
        </div>
    );
};

export default VITercerBillete;
