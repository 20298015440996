import React from "react";
import AppRouter from "./routers/AppRouter";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "./App.css";

const App = () => {
    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={3}>
                <AppRouter />
            </SnackbarProvider>
        </Provider>
    );
};

export default App;
