import React from "react";

import {
    Box,
    Button,
    Container,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";

import Icons from "../helpers/icons";
import { Link } from "react-router-dom";

const LandingScreen = () => {
    return (
        <Container
            className={"text-focus-in"}
            sx={{
                minHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
            }}
        >
            <Typography
                variant="h1"
                component="h1"
                sx={{
                    fontWeight: "bold",
                    fontSize: {
                        xs: "5rem", // Tamaño para pantallas pequeñas (móviles)
                        // sm: "10rem", // Tamaño para pantallas medianas (tablets)
                        md: "6rem", // Tamaño para pantallas grandes
                    },
                }}
            >
                Alejandro Loda
            </Typography>

            <Typography
                variant="h5"
                component="h2"
                sx={{ mt: 2, color: "text.secondary" }}
            >
                Web en construcción
            </Typography>

            <Box sx={{ mt: 4 }}>
                <Tooltip title="mago.alejandroloda" arrow placement="bottom">
                    <IconButton
                        color="primary"
                        aria-label="Instagram"
                        onClick={() =>
                            window.open(
                                "https://www.instagram.com/mago.alejandroloda",
                                "_blank"
                            )
                        }
                    >
                        <Icons.Instagram sx={{ fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title="hola@alejandroloda.com"
                    arrow
                    placement="bottom"
                >
                    <IconButton
                        color="primary"
                        aria-label="Email"
                        onClick={() =>
                            window.open(
                                "mailto:hola@alejandroloda.com",
                                "_blank"
                            )
                        }
                    >
                        <Icons.Email sx={{ fontSize: 40 }} />
                    </IconButton>
                </Tooltip>
                <Tooltip
                    title={
                        <div style={{ textAlign: "center" }}>
                            <strong>Pocket Card</strong>
                            <br />
                            El enfile del Mentalista
                        </div>
                    }
                    arrow
                    placement="bottom"
                >
                    <IconButton
                        color="primary"
                        aria-label="Email"
                        onClick={() =>
                            window.open("https://amzn.eu/d/dq3BYOi", "_blank")
                        }
                    >
                        <Icons.Amazon sx={{ fontSize: 32 }} />
                    </IconButton>
                </Tooltip>
            </Box>
            <br />
            <Button variant="outlined" component={Link} to={"/magia"}>
                Mis libros de Magia
            </Button>
        </Container>
    );
};

export default LandingScreen;
