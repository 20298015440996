import React from "react";

const VIArana = () => {
    return (
        <div>
            A continuación se describe como producir la araña.
            <br />
            <b>Producción de la araña.</b>
            <br />
            <br />
            Partimos de la situación de haber producido el tercero de los
            billetes. <br />
            Acabamos de devolver la tarjeta, y dicho que al tercer espectador le
            van las cosas algo más justas que al segundo.
            <br />
            Tendremos ahora una Pocket Card vacía en la mano. Deberemos cargar
            la araña y producirla.
            <br />
            <br />
            <ul
                style={{
                    listStyleType: "disc",
                    paddingLeft: "20px",
                }}
            >
                <li>
                    Tras devolver el billete, quédate un segundo pensativo y
                    sentado de manera relajada.
                </li>
                <li>
                    Di que se te ocurre, que lo más justo quizás sería que esta
                    noche, sea el segundo espectador el que invite, que ya otro
                    día cuando las cosas vayan mejor pues le tocará al tercero.
                    Señala con tu mano derecha con la palma hacia arriba al
                    tercer espectador y dile "¿No te parece bien?"
                </li>
                <li>
                    En este acción, tu mano izquierda (vacía), descansa sobre el
                    regazo fuera de la vista del público, de manera que puede
                    cargar la araña en empalme de dedos.
                    <br />
                    Debes hacer que el extremo de la cuerda apunte a la derecha
                    y se sostenga entre el índice y el corazón.
                </li>
                <li>
                    Volviendo al juego, te toca con tu tarjeta, y dices que tu
                    vas bastante sobrado.
                </li>
                <li>
                    Toma en la punta de los dedos índice y pulgar el datáfono y
                    haces la prueba con el "tap" e insertándola, pero ninguno
                    funciona.
                    <br />
                    Esto es así para manterner oculto el empalme.
                </li>
                <li>
                    Cuando vayas a probar deslizando, el datáfono ahora sí
                    descansa sobre la araña, y con tu tarjeta, debes agarrar la
                    cuerda de la araña que tenías entre el índice y el corazón.
                </li>
                <li>
                    Con cuidado desliza, y aparecerá la araña en lugar del
                    dinero. Esto da a entender que "estas pelado", así que haz
                    un silencio.
                </li>
                <li>
                    Intentando fingir que no le das importancia, di "bueno
                    quizás no me va tan tan bien... Por si acaso se vuelan...
                    voy a recoger estos billetes, y... mejor lo dejamos aquí, ya
                    arreglaremos luego..."
                </li>
                <li>
                    Recoge el datáfono y los billetes y los guardas en el
                    bolsillo izquierdo, en el momento de guardarlos, puedes
                    coger de la silla el datáfono normal del principio, y
                    guardarlo también.
                </li>
            </ul>
        </div>
    );
};

export default VIArana;
